import { gql } from 'graphql-request';

export const getPartnerPickerConfiguration = gql`
    query GetPartnerPickerConfiguration {
        getPartnerPickerConfiguration {
            partner
            productType
            configuration {
                enabled
                requireProductType
                defaultPath
                logoUrl
                order
            }
        }
    }
`;
