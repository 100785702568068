import { EvidentlyProvider, EvidentlyProviderConfiguration } from './evidently';
import {
    OneServiceApiProvider,
    OneServiceApiProviderConfiguration,
} from './oneservice-api';
import { Provider, ProviderConfiguration, ProviderInstance } from './Provider';

import { DefaultEvidentlyConfiguration } from './evidently/defaults';
import { DefaultOneServiceApiProviderConfiguration } from './oneservice-api/defaults';

export const createProvider = <T extends Provider>(
    provider: T,
    config?: ProviderConfiguration<T>
): ProviderInstance<T> => {
    let instance;

    switch (provider) {
        case Provider.Evidently:
            instance = new EvidentlyProvider(
                (config as EvidentlyProviderConfiguration) ??
                    DefaultEvidentlyConfiguration
            );
            break;
        case Provider.OneServiceApi:
            instance = new OneServiceApiProvider(
                (config as OneServiceApiProviderConfiguration) ??
                    DefaultOneServiceApiProviderConfiguration
            );
            break;
        default:
            break;
    }

    return instance as ProviderInstance<T>;
};
