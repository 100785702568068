/* eslint-disable @typescript-eslint/promise-function-async */
import { Evidently, EvidentlyClientConfig } from '@aws-sdk/client-evidently';

import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';
import { ConfigProvider } from '../Provider';
import { fromWebToken } from '@aws-sdk/credential-providers';
import { getIdentity } from '@soluto-private/mx-analytics';

export type EvidentlyProviderConfiguration = {
    project: string;
    entityId?: string;
    credentials: {
        identityPoolId: string;
        roleArn: string;
    };
    context?: Record<string, unknown>;
} & Omit<EvidentlyClientConfig, 'credentials'>;

export class EvidentlyProvider implements ConfigProvider {
    private readonly _client: Evidently;

    constructor(
        private readonly _configuration: EvidentlyProviderConfiguration
    ) {
        // Apply default values to configuration
        this._configuration = {
            entityId: getIdentity('UserBrowserId'),
            region: 'us-east-1',
            context: {},
            ...this._configuration,
        };

        const {
            region,
            credentials: { identityPoolId, roleArn },
            ...rest
        } = this._configuration;

        const cognito = new CognitoIdentity({ region });
        const credentials = () =>
            cognito
                .getId({ IdentityPoolId: identityPoolId })
                .then(({ IdentityId }) =>
                    cognito.getOpenIdToken({ IdentityId })
                )
                .then(({ Token }) => Token)
                .then((webIdentityToken) =>
                    fromWebToken({
                        roleArn,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        webIdentityToken: webIdentityToken!,
                        clientConfig: { region },
                    })()
                );

        this._client = new Evidently({ region, credentials, ...rest });
    }

    public async get<T>(
        key: string,
        extraData: Record<string, unknown> = {}
    ): Promise<T> {
        const { project, entityId, context } = this._configuration;

        const evaluationContext = JSON.stringify({
            hostname: window.location.hostname,
            ...context,
            ...extraData,
        });

        const data = await this._client.evaluateFeature({
            project,
            entityId,
            feature: key,
            evaluationContext,
        });

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return Object.values(data.value!)[0] as T;
    }
}
