/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { EvidentlyProviderConfiguration } from '../evidently';

export const DefaultEvidentlyConfiguration: EvidentlyProviderConfiguration = {
    region: process.env.EVIDENTLY_REGION,
    endpoint: process.env.EVIDENTLY_ENDPOINT,
    project: process.env.EVIDENTLY_PROJECT!,
    credentials: {
        identityPoolId: process.env.EVIDENTLY_IDENTITY_POOL_ID!,
        roleArn: process.env.EVIDENTLY_ROLE_ARN!,
    },
};
