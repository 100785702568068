import { gql } from 'graphql-request';

export const getPartnerProductConfiguration = gql`
    query GetPartnerProductConfiguration(
        $input: GetPartnerProductConfigurationInput!
    ) {
        getPartnerProductConfiguration(input: $input) {
            partnerDisplayName
            productDisplayName
            expertChat {
                enabled
                key
            }
            header {
                accountNav {
                    enabled
                    links {
                        label
                        type
                        url
                    }
                }
                logo {
                    alt
                    url
                }
                subNav {
                    logo {
                        alt
                        url
                    }
                }
                waffleMenu {
                    enabled
                }
            }
            leftNav {
                displayPartnerLogo
                enabled
                links {
                    label
                    type
                    url
                }
            }
            i18n {
                defaultLocale
                supportedLocales
            }
            footer {
                links {
                    label
                    type
                    url
                }
            }
            partnerPicker {
                defaultPath
                enabled
                logoUrl
                order
                requireProductType
            }
        }
    }
`;
