import { EvidentlyProvider, EvidentlyProviderConfiguration } from './evidently';
import {
    OneServiceApiProvider,
    OneServiceApiProviderConfiguration,
} from './oneservice-api';

export enum Provider {
    Evidently = 'evidently',
    OneServiceApi = 'oneservice-api',
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface ConfigProvider {
    get<T>(key: string): Promise<T>;
}

export type ProviderInstance<T extends Provider> =
    T extends Provider.OneServiceApi
        ? OneServiceApiProvider
        : T extends Provider.Evidently
        ? EvidentlyProvider
        : undefined;

export type ProviderConfiguration<T extends Provider> =
    T extends Provider.OneServiceApi
        ? OneServiceApiProviderConfiguration
        : T extends Provider.Evidently
        ? EvidentlyProviderConfiguration
        : unknown;
