import { Partner, ProductType } from '@soluto-private/mx-types';
import {
    PartnerPickerConfiguration,
    PartnerProductConfiguration,
} from '@soluto-private/mx-types';
import {
    getPartnerPickerConfiguration,
    getPartnerProductConfiguration,
} from './query';

import { ConfigProvider } from '../Provider';
import { GraphQLClient } from 'graphql-request';

export type OneServiceApiProviderConfiguration = {
    url: string;
    apiKey: string;
};

type EnhancedPartnerPickerConfiguration = {
    partner: Partner;
    productType: ProductType;
    configuration: PartnerPickerConfiguration;
};

export class OneServiceApiProvider implements ConfigProvider {
    private readonly _client: GraphQLClient;

    constructor(_configuration: OneServiceApiProviderConfiguration) {
        this._client = new GraphQLClient(_configuration.url, {
            headers: { 'X-Api-Key': _configuration.apiKey },
        });
    }

    public async get<T>(
        key: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        extraData: Record<string, unknown> = {}
    ): Promise<T> {
        return undefined as T;
    }

    public async getPartnerProductConfiguration(
        partner: Partner,
        productType?: ProductType
    ): Promise<PartnerProductConfiguration | null> {
        const input = { partner, productType };
        const { getPartnerProductConfiguration: configuration } =
            await this._client.request<{
                getPartnerProductConfiguration: PartnerProductConfiguration;
            }>(getPartnerProductConfiguration, { input });
        return configuration;
    }

    public async getPartnerPickerConfiguration() {
        const { getPartnerPickerConfiguration: configuration } =
            await this._client.request<{
                getPartnerPickerConfiguration: EnhancedPartnerPickerConfiguration[];
            }>(getPartnerPickerConfiguration);
        return configuration;
    }
}
